@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed&family=Bebas+Neue&family=Space+Mono&display=swap');

.App {
  text-align: center;
  height: fit-content;
  color: white;
  background-color: #282c34;
  overflow-y: hidden;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App-header{
  background: radial-gradient(circle at 100%, #9a0da4, #110639 50%, #1c1ce7 80%, #000000 75%);
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 4em;
  font-size: calc(10px + 2vmin);
  color: white;

}

/* Navbar */
.navbar{
  font-family: 'Barlow Condensed', sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* position: relative; */
  align-items: center;
  width: 100%;
  background:rgba(0,0,0,0);
}

.logo-img{
  margin:1em;
  width: 3em;
}

.nav-links{
  display: flex;
  flex-direction: row;
  margin-block-end:1em;
}

.nav-links .links-li{
  text-decoration: none;
  color: white;
  margin: 0 .5em;
  font-size: 0.8em;
  padding:0.5em;
  border-radius: 5px;
}
.nav-links .links-li:hover{
  color: #b316d6;
}

.hidden{
  display: none;
}

.mobileNavToggle{
  display:none;
}


@media (max-width:40em) {

  .mobileNavToggle{
    display:block;
    position: fixed;
    top: 2em;
    right: 1em;
    z-index: 100;
    color: white;
    padding: 0.2em;
    border-radius: 3em;
  }

  .mobileNavToggle:hover{
   border: 1.5px solid #7d7c7e;
  }

  .navSections ul{
    display: flex;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;

    padding: min(20vh, 7em) 1em;
    /* gap:2em; */
    height: 100%;

    inset: 0 0 0 60%;
    background: radial-gradient(circle at 100%, #9a0da4, #110639 50%, #1c1ce7 80%, #000000 75%);
    transform: translateX(100%);
    transition: transform 0.5s ease-in-out;
    
  }

  .navSections ul[data-visible="true"]{
    transform: translateX(0%);
  }


  .nav-links{
    display: flex;
    flex-direction: column;
    gap:2.5em;
  }
  
}



/* End Navbar */


.Landing-Header{
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 1em;
}
.Landing-Title{
  font-family: 'Bebas Neue';
  font-weight: 400;
  font-size: 3em;
  margin: .2em;
}
.About{
  height:auto;
  background-color: #282c34;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.About-Description{
  font-family: 'Space Mono', monospace;
  font-weight: 100;
  font-size: large;
  text-align: left;
  padding-left: 5vw;
  padding-top: 5vh;
  width: 40vw;
  margin-right: auto;
}
.MuiButtonBase-root.MuiButton-outlined {
  font-family: 'Space Mono', monospace;
  background:  #432145;
  width: 10vw;
  height: 5vh;
  padding-top: 1em;
  padding-bottom: 1em;
  margin: 5em;
}
.MuiButtonBase-root.MuiButton-outlined:disabled{
  color: white;
}
.Video{
  margin-left: auto;
  padding-top: 5vh;
  padding-bottom: 5vh;
  max-height: 60vh;
  margin-right: 10vw;
}

/* Prize Section */

.Prizes{
  height:auto;
  background-color: #282c34;
  display: flex;
  flex-flow: wrap;
  font-family: 'Space Mono', monospace;
  margin-bottom: 5em;
}

.Prizes-Top-Description{
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.Prizes-Title{
  align-items: center;
  font-size: xx-large;
  padding-top: 5vh;
  padding-bottom: 5vh;
}

.Prizes-Description{
  font-size: large;
  font-weight: 100;
  font-size: large;
  text-align: center;
  padding-left: 15vh;
  padding-right: 15vh;
}

.First-Place, .Second-Place, .Third-Place{
  display: flex;
  padding-top: 5vh;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.Left-Title, .Left-Name{
  font-size: xx-large;
  font-weight: 100;
  color:gold;
}

.Prizes-Prizes-left, .Prizes-Name-left{
  font-size: large;
  font-weight: 100;
  text-align: left;
  padding-left: 5vh;
  padding-right: 5vh;
}

.image-one{
  height: 50vh;
  width: 50vh;
}
.image-two{
  height: 50vh;
  width: 50vh;
}
.image-three{
  height: 35vh;
  width: 50vh;
}

.Prizes-Name-left{
  color: lightblue;
}

.Right-Title, .Name{
  font-size: large;
  font-family: 'Space Mono', monospace;
  font-weight: 100;
  font-size: xx-large;
  color:gold;
}

.Prizes-Prizes-right , .Prizes-Name-right{
  font-size: large;
  font-weight: 100;
  text-align: left;
  font-family: 'Space Mono', monospace;
}

.Prizes-Name-right{
  color: lightblue;
}

.First-Place , .Second-Place , .Third-Place{
  padding: min(20vh, 5em) 1em;  
}

.First-Place{
  background: linear-gradient(to bottom, #282c34, #110639);
}
.Third-Place{
  background: linear-gradient(to bottom,#110639, #282c34);
}
.Second-Place{
  background-color: #110639;
}
/* End of prize section */
#footer{
  padding-top: 10em;
}

/*END Footer*/

/*START Team*/
.Team{
  height:auto;
  font-family: 'Space Mono', monospace;
  font-weight: 100;
}
.Team-Img{
  box-shadow: -5px -5px 10px rgba(81, 81, 81, 0.5),
            5px 5px 10px rgba(70,70,70,0.12);
  border-radius: 25%;
}
.Team-Member-Div{
  padding-bottom: 5em;
  padding-left: 10vw;
  padding-right: 10vw;
}
.Team-Description{
  overflow-wrap: break-word;
  font-weight: 100;
}

@media (max-width: 1250px) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    }
  .App-link {
    color: #61dafb;
  }
  .About{
    overflow-y: hidden;
    justify-content: space-between;
    max-width: auto;
    flex-direction: column;
    align-items: left;
  }
  .About-Description{
    font-size: medium;
    text-align:inherit;
    width: 80vw;
    padding-left: 10vw;
  }
  .MuiButtonBase-root.MuiButton-contained {
    width: 10vw;
    font-size: small;
    font-weight: 100;
    height: 10vh;
    width: 10vw;
    padding-left : 5em;
    padding-right: 5em;

  }
  .Video{
    padding-left: 0%;
    margin: 0;
    height: 50vh;
    width: 95vw;
    align-content: left;
    position: initial;
  }

  /* Prize section mobile */

  .First-Place , .Second-Place , .Third-Place{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .Right-Div h3, .Left-Div h3{
    text-align: center;
  }

  .image-one{
    height: 30vh;
    width: 30vh;
  }
  .image-two{
    height: 30vh;
    width: 30vh;
  }

  .image-three{
    height: 25vh;
    width: 40vh;
  }

  .image-one, .image-two, .image-three{
    padding-block: 2em;
  }

  /* End of prize section mobile */

  /*Sponsors Mobile Viewport*/
  .sponsorList{
    padding-right: 5vw;
    width: 95vw;
  }
  /*Footer Mobile Viewport*/
  #footer{
    padding-top: 30em;
  }
}

/* Sponsor Section */
.sponsorSection{
  font-family: 'Space Mono', monospace;
  background: linear-gradient(to bottom, #282c34, #110639);
  height: 50vh;
  display: flex;
  padding-block:8em;
  justify-content: center; 
}

.sponsorSection:first-child{
  display: flex;
  flex-direction: column;
}
.sponsorSection h2{
  font-size: xx-large;
  margin: 2em;
}
.sponsorSection h4{
  font-size: 1em;
}

